<template>
  <!-- Card -->
  <div class="card" v-bind:class="{ 'position-relative': construction }">
    <div class="construction-mode text-white" v-if="construction">
      <i class="fas fa-exclamation-triangle text-warning fa-lg"></i>
      <span>{{$t('underConstruction')}}</span>
    </div>
    <Header
      v-if="header"
      v-bind:background="background"
      v-bind:actions="actions"
      v-bind:bread-crumb="breadCrumb"
      v-bind:title="title"
      v-bind:class="{ blur: construction }"
    />
    <div class="card-body" v-bind:class="[bodyClass, { blur: construction }]">
      <slot></slot>
    </div>
  </div>
  <!-- ./Card -->
</template>
<script>
const Header = () => import("./components/Header/Index");

export default {
  name: "WeCard",
  components: {
    Header,
  },
  props: [
    "header",
    "background",
    "actions",
    "bread-crumb",
    "title",
    "construction",
    "bodyClass",
  ],
};
</script>
<style lang="scss" scoped>
.construction-mode {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1020;
  border-radius: 0.25rem;
  user-select: none;
  span {
    margin-left: 10px;
    font-size: 1.5rem;
  }
}
.blur {
  filter: blur(2.5px);
  -webkit-filter: blur(2.5px);
}
</style>
